<template>
   <div>
        <div class="kt-heading kt-heading--md">Cari Pasien</div>
        <form @submit.prevent="search">
            <div class="row">
            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-12 col-form-label">Nama Pasien :</label>
                    <div class="col-12">
                        <input type="text" class="form-control" placeholder="Nama Pasien" v-model="nameSearch">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-12 col-form-label">Tanggal Lahir:</label>
                    <div class="col-12 field-date-picker">
                        <vue-date-picker
                            v-model="birthDaySearch"
                            :disabled-date="'notAfterToday'"
                        >
                        </vue-date-picker>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-12 col-form-label">Nomor Telepon :</label>
                    <div class="col-12">
                        <phone-number-patient
                            :data="phoneNumberSearch"
                            :is-validate="false"
                            :changedValue.sync="phoneNumberSearch">
                        </phone-number-patient>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-12 col-form-label"> &nbsp; </label>
                    <div class="col-12">
                        <button type="submit" class="btn btn-default btn-bold btn-upper d-flex" :disabled="loading">
                            <template v-if="loading">
                                <span style="margin-right:5px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </template>
                            <template v-else>
                                <i class="flaticon2-search"></i> {{ $t('button.search') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </form>
        
        <div class="row">
            <div class="col-12">
                <!-- <v-client-table ref="table" :data="tableData" :columns="columns" :options="options">
                <div slot="action" slot-scope="{ row }" class="text-center">
                    <button class="btn btn-default" @click="select(row)">Pilih</button>
                </div>
                </v-client-table> -->
                <div class="progress-bar">
                    <div v-show="loading" class="progress-bar-value"></div>
                </div>
                <v-server-table
                    id="holiday-table"
                    class="floating-action"
                    :columns="columns"
                    :options="options" 
                    name="patient_table"
                    ref="table"
                    @loaded="onLoaded(false)"
                    @loading="onLoaded(true)"
                >
                    <!-- @loaded="onLoaded(0)"
                    @loading="onLoaded(1)" -->
                    <div slot="action" slot-scope="{ row }" class="text-center">
                        <button class="btn btn-default" @click="select(row)">Pilih</button>
                    </div>
                </v-server-table>
            </div>
        </div>

    </div>
</template>

<script>
    import PhoneNumberPatient from "./../_general/PhoneNumber";
    import VueDatePicker from './../_general/VueDatePicker.vue';
    import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
    const PatientRepository = RepositoryFactory.get("patient");

    import { Event } from "vue-tables-2";
    
    export default {
        model: {
            prop: 'value',
            event: 'input'
        },
        props: {
            value: {
                type: Object,
            },
        },
        components: {
            VueDatePicker,
            PhoneNumberPatient
        },
        data() {
            var vx = this
            return {
                nameSearch: null,
                birthDaySearch: null,
                phoneNumberSearch: null,
                tableData:[],
                columns: ['patientName','dateOfBirth','mobilePhone1', 'email', 'jenisKelamin.name', 'medicalNo', 'action'],
                options: {
                    filterable: false,
                    customFilters: ["filterQuery"],
                    headings: {
                        patientName: 'Nama Pasien',
                        dateOfBirth: 'Tanggal Lahir',
                        mobilePhone1: 'Nomor Telepon',
                        email: 'Email',
                        'jenisKelamin.name': 'Jenis Kelamin',
                        medicalNo: 'Nomor Medical Record',
                    },
                    dateColumns: ['dateOfBirth'],
                    dateFormat: 'DD-MMM-YYYY',
                    perPageValues: [],
                    perPage:"5",
                    requestFunction(data) {
                        if (!data.filterQuery) {
                            return new Promise((resolve) => {
                                resolve({
                                    data: [],
                                    headers: {
                                        'x-total-count': 0
                                    }
                                })
                            })
                        }
                        if (this.abortController) {
                            this.abortController.abort()
                        }

                        let params = {};
                        params.page = data.page-1;
                        params.size = data.limit;
                        params.sort = 'patientName'
                        // this.isLoaded = false
                        let methodStr = `fetch`
                        
                        if (data.filterQuery) {
                            params.sort = 'patientName.keyword'
                            params.query = data.filterQuery
                            
                            methodStr = `search`
                            
                        } 
                        const repo = PatientRepository[methodStr](params) // PatientRepository.fetch
                        // PatientRepository.search
                        this.abortController = repo.next().value
                        const reposi = repo.next().value
                        repo.next()
                        // vx.loading = true;
                        return reposi.catch(function (e) {
                                // vx.loading = false;
                                console.log(e , `error ${methodStr}`);
                            });
                    },
                    responseAdapter: (resp) => {  
                        // vx.loading = false;
                        return {
                            data: resp.data,
                            count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                        }
                    },
                },
                showTable: false,
                loading: false,
                abortController: null,
            }
        },
        methods: {
            select(row){
                this.$emit('input', row);
                this.$emit('select', row);
            },
            search(){
                var queryParam = {};
                if(!helpers.isNullOrEmpty(this.nameSearch)) queryParam.patientName = this.nameSearch;
                if(!helpers.isNullOrEmpty(this.birthDaySearch)) queryParam.dateOfBirth = {start: this.birthDaySearch, end: this.birthDaySearch};
                if(!helpers.isNullOrEmpty(this.phoneNumberSearch) && this.phoneNumberSearch != '62') queryParam.mobilePhone1 = this.phoneNumberSearch;
                if(!_.isEmpty(queryParam)){
                    // this.loading = true;
                    // var params = { query : helpers.filterTable(queryParam)};
                    // PatientRepository.search(params).then((res) => {
                    //     this.tableData = res.data;
                    //     this.showTable = true;
                    //     this.loading = false;
                    // }).catch(err => {
                    //     this.loading = false;
                    // })
                    Event.$emit(`vue-tables.patient_table.filter::filterQuery` ,helpers.filterTable(queryParam))
                } else {
                    // this.tableData = [];
                    Event.$emit(`vue-tables.patient_table.filter::filterQuery`)
                }
            },
            onLoaded(valBoolean){
                this.loading = valBoolean
            }

        },
    }
</script>

<style lang="scss" scoped>

</style>
